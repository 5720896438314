<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <el-page-header @back="$router.go(-1)" content="专家抽取" />
        </div>
        <div style="margin: 15px 0;display:flex">
            <div style="width:240px;padding-right:20px;">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>待抽取项目</span>
                    </div>
                    <div style="height:400px;overflow: scroll;">
                        <!-- <div v-for="o in projects" :key="o.id" class="text item">
                            {{ o.name }}
                        </div> -->
                        <el-tree :data="projects" :props="defaultProps" @node-click="handleNodeClick"
                            default-expand-all></el-tree>
                    </div>
                </el-card>
            </div>
            <div style="flex:1;padding-right:20px;">
                <el-card class="box-card" shadow="hover" v-show="active == 0">
                    <div slot="header" class="clearfix">
                        <span>当前项目</span>
                    </div>
                    <div style="height:400px;overflow: scroll;">
                        <el-descriptions title="项目信息" v-if="form.num">
                            <el-descriptions-item label="项目编号">{{ form.num }}</el-descriptions-item>
                            <el-descriptions-item label="项目名称">{{ form.name }}</el-descriptions-item>
                            <el-descriptions-item label="创建时间">{{ form.created }}</el-descriptions-item>
                            <el-descriptions-item label="项目类型">
                                <el-tag size="small">{{ form.type }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系人">{{ form.user }}</el-descriptions-item>
                            <el-descriptions-item label="联系地址">{{ form.addr }}</el-descriptions-item>
                        </el-descriptions>
                        <el-empty v-else description="请选择项目"></el-empty>
                    </div>
                </el-card>
                <el-card class="box-card" shadow="hover" v-show="active == 1">
                    <div slot="header" class="clearfix">
                        <span>回避设置</span>
                    </div>
                    <div style="height:400px;overflow: scroll;">
                        <el-transfer filterable :filter-method="filterMethod" filter-placeholder="名称搜索" v-model="form.huibi"
                            :titles="['专家列表', '回避人员']" :props="{ key: 'id', label: 'name' }" :data="experts">
                        </el-transfer>
                    </div>
                </el-card>
                <el-card class="box-card" shadow="hover" v-show="active == 2">
                    <div slot="header" class="clearfix">
                        <span>抽取条件设置</span>
                    </div>
                    <div style="height:400px;overflow: scroll;">
                        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-form">
                            <el-form-item label="抽取人数" prop="name">
                                <el-input v-model="form.count" style="width:200px">
                                    <template slot="append">人</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="专业类别" prop="type">
                                <el-checkbox-group v-model="form.type">
                                    <el-checkbox label="软件开发" name="type"></el-checkbox>
                                    <el-checkbox label="技术服务" name="type"></el-checkbox>
                                    <el-checkbox label="医疗卫生服务" name="type"></el-checkbox>
                                    <el-checkbox label="装修工程" name="type"></el-checkbox>
                                    <el-checkbox label="印刷出版" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="专家职称" prop="type">
                                <el-checkbox-group v-model="form.zhicheng">
                                    <el-checkbox label="主任医师" name="type"></el-checkbox>
                                    <el-checkbox label="副主任医师" name="type"></el-checkbox>
                                    <el-checkbox label="主治医师" name="type"></el-checkbox>
                                    <el-checkbox label="高级工程师" name="type"></el-checkbox>
                                    <el-checkbox label="中级工程师" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="专家学历" prop="type">
                                <el-checkbox-group v-model="form.xueli">
                                    <el-checkbox label="博士" name="type"></el-checkbox>
                                    <el-checkbox label="硕士" name="type"></el-checkbox>
                                    <el-checkbox label="本科" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
                <el-card class="box-card" shadow="hover" v-show="active == 3">
                    <div slot="header" class="clearfix">
                        <span v-if="loading">正在抽取</span>
                        <span v-else>抽取完成</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="choose">重新抽取</el-button>
                    </div>
                    <div style="height:400px;overflow: scroll;" v-loading="loading">
                        <el-row :gutter="10">
                            <el-col :span="8" v-for="(item, index) in this.form.experts" :key="index">
                                <el-card shadow="hover" style="margin-bottom: 10px">
                                    <div class="expert-card" @click="onAdd(item.id)">
                                        <el-image class="img" :src="item.imageUrl" style="width: 100px;height: 140px">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                        <div class="content">
                                            <div>姓名：{{ item.name }}</div>
                                            <div>专业：{{ item.zhuanye }}</div>
                                            <div>职称：{{ item.zhicheng }}</div>
                                            <div>学历：{{ item.xueli }}</div>
                                            <div>简介：{{ item.jieshao }}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <el-card class="box-card" shadow="hover" v-show="active == 4">
                    <div slot="header" class="clearfix">
                        <span>抽取完成</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="choose">重新抽取</el-button>
                    </div>
                    <div style="height:400px;overflow: scroll;">
                        <el-result icon="success" title="成功提示" subTitle="请根据提示进行操作">
                            <template slot="extra">
                                <el-button type="primary" size="medium">打印报告</el-button>
                            </template>
                        </el-result>
                    </div>
                </el-card>
                <div style="margin: 30px auto;text-align:left" v-if="active < 5">
                    <el-button @click="prevStep">上一步</el-button>
                    <el-button type="primary" @click="nextStep">{{ this.active != 4 ? '下一步' : '完成' }}</el-button>
                </div>
            </div>
            <div style="width: 200px; height: 500px;padding:5px 0;">
                <el-steps :active="active" direction="vertical">
                    <el-step title="选择项目" description="选择已登记项目"></el-step>
                    <el-step title="抽取回避设置" description="设置回避规则"></el-step>
                    <el-step title="抽取条件设置" description="设置抽取条件"></el-step>
                    <el-step title="抽取完成" description="预览抽取结果"></el-step>
                    <el-step title="抽取结果" description="抽取完成"></el-step>
                </el-steps>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            active: 0,
            experts: [],
            form: {
                count: 3,
                huibi: [],
                type: [],
                zhicheng: [],
                xueli: [],
                experts: [],
            },
            filterMethod(query, item) {
                return item.name.indexOf(query) > -1;
            },
            // projects: [],
            rules: {},
            projects: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            }
        }
    },
    methods: {
        handleNodeClick(data) {
            this.active = 0
            this.form = { ...this.form, ...data }
            console.log(this.form)
        },
        choose() {
            // 循环3次，每次从数组中随机抽取一个元素，并将其从数组中删除
            this.form.experts = []
            this.loading = true
            setTimeout(() => {
                for (let i = 0; i < parseInt(this.form.count); i++) {
                    // 随机生成一个索引值
                    let index = Math.floor(Math.random() * this.experts.length);
                    // 从数组中删除该元素，并将其添加到结果数组中
                    this.form.experts.push(this.experts.splice(index, 1)[0]);
                }
                this.loading = false
            }, 1000)
        },
        prevStep() {
            if (this.active > 0) this.active -= 1
        },
        nextStep() {
            if (this.active <= 4) this.active += 1
            if (this.active == 3) this.choose()
            if (this.active == 5) {
                this.$http.post('/expert/choose', this.form)
                    .then(resp => {
                        this.projects.forEach(node => {
                            // 使用 filter() 方法过滤掉要删除的元素
                            node.children = node.children.filter(item => item.name !== this.form.name);
                        });
                        this.$message[resp.data.type](resp.data.msg)
                        this.active = 0
                        this.form = {
                            count: 3,
                            huibi: [],
                            type: [],
                            zhicheng: [],
                            xueli: [],
                            experts: [],
                        }
                    }).catch(err => {
                        this.$message.error(err.message)
                        this.active = 4
                    }).finally(() => {
                        this.loading = false
                    })
            }
        },
        getData() {
            this.$http.get('/project/tree?page=1&pageSize=100&status=未开始')
                .then(resp => {
                    console.log(121, resp.data)
                    this.projects = resp.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
            this.$http.get('/expert/table?page=1&pageSize=100')
                .then(resp => {
                    console.log(resp.data.data)
                    this.experts = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.expert-card {
    display: flex;
    cursor: pointer;

    .img {
        width: 120px;
        height: 160px;
        text-align: center;
    }

    .content {
        flex: 1;
        padding: 0 10px;
    }
}
</style>